import React from 'react'
import { useFileContext } from '../context/FileContext.tsx'
import { Anchor, Text, List, ThemeIcon, rem } from '@mantine/core'
import { IconBrandYoutubeFilled, IconExternalLink } from '@tabler/icons-react'

const ExternalLinksView: React.FC = () => {
    const { parsedPiece } = useFileContext()
    // {/*[{index + 1}] {link.url.replace(/\s+/g, ' ').trim()} <br />*/}

    if (parsedPiece?.properties.links.length === 0) {
        return <></>
    }

    const youtubeicon = (
        <ThemeIcon color="#FF0831" size={24} radius="xl">
            <IconBrandYoutubeFilled
                style={{ width: rem(16), height: rem(16) }}
            />
        </ThemeIcon>
    )

    const defaulticon = (
        <ThemeIcon color="teal" size={24} radius="xl">
            <IconExternalLink style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
    )

    const links = parsedPiece?.properties.links.map((link, index) => {
        const hostname = new URL(link.url).hostname.replace(/^www\./, '')
        const isYoutube = hostname === 'youtube.com' || hostname === 'youtu.be'

        return (
            // <Button
            //     leftSection={
            //         isYoutube ? (
            //             <IconBrandYoutubeFilled
            //                 style={{ width: rem(16.5), height: rem(16.5) }}
            //             />
            //         ) : (
            //             <IconExternalLink
            //                 style={{ width: rem(16), height: rem(16) }}
            //             />
            //         )
            //     }
            //     component="a"
            //     target="_blank"
            //     variant="filled"
            //     color={isYoutube ? '#FF0831' : 'teal'}
            //     size="xs"
            //     radius="xl"
            //     href={link.url}
            //     // data-disabled
            //     // onClick={(event) => event.preventDefault()}
            // >
            //     {link.name}{' '}
            // </Button>
            <List.Item icon={isYoutube ? youtubeicon : null} key={index}>
                <Anchor
                    href={link.url}
                    target="_blank"
                    c={'var(--mantine-color-text)'}
                >
                    <Text lineClamp={1} c={'text'}>
                        {link.name}{' '}
                    </Text>
                    {/*<Text size={rem(12)} lineClamp={1}>*/}
                    {/*    {}*/}
                    {/*</Text>*/}
                </Anchor>
            </List.Item>
        )
    })

    return (
        <>
            {/*<Container size="60rem">*/}
            {/*    <Card radius={'md'}>*/}
            {/*<Group>*/}
            {/*    <IconLink size={20} /> /!* Adjust the size as needed *!/*/}
            {/*    <Text fw={700} lineClamp={1}>*/}
            {/*        Links*/}
            {/*    </Text>*/}
            {/*</Group>*/}
            <List
                spacing="xs"
                size="xs"
                ml={rem(0)}
                mt={0}
                center
                icon={defaulticon}
            >
                {/*<Title>External Links</Title>*/}
                {/*<Group justify="center" gap="xs">*/}
                {links}
                {/*</Group>*/}
            </List>
            {/*</Card>*/}
            {/*</Container>*/}
        </>
    )
}

export default ExternalLinksView
