/* eslint-disable @typescript-eslint/no-unused-vars */
import Split from 'react-split-grid'
import {
    Affix,
    AppShell,
    Burger,
    Card,
    Container,
    createTheme,
    Group,
    MantineColorsTuple,
    MantineProvider,
    NavLink,
    ScrollArea,
    Space,
    Stack,
    Tabs,
    Text,
} from '@mantine/core'
import { useDisclosure, useMediaQuery, usePrevious } from '@mantine/hooks'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import './App.css'
import { useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import axios, { CancelTokenSource } from 'axios'
import IframePDFViewer from './components/IframePDFViewer.tsx'
import MultiInstrumentPlayer from './MultiInstrumentPlayer.tsx'
import { HeaderMenu } from './components/HeaderMenu.tsx'
// import { PlaybackBar } from './components/PlaybackBar.tsx'
import { useFileContext } from './context/FileContext.tsx'
import * as Sentry from '@sentry/react'
import RightSidebarContents from './components/RightSidebarContents.tsx'
// import LibraryView from './components/LibraryView.tsx'
import {
    IconAdjustments,
    IconEdit,
    IconFile,
    // IconHome2,
    IconLayoutGrid,
} from '@tabler/icons-react'
import PieceGridView from './components/PieceGridView.tsx'
import ExportPDFButton from './components/ExportPDFButton.tsx'
import OpenFileButton from './components/OpenFileButton.tsx'
import SaveFileButton from './components/SaveFileButton.tsx'
import { CodeEditor } from './components/CodeEditor.tsx'
import { Notifications } from '@mantine/notifications'
// import { PlaybackBar } from './components/PlaybackBar.tsx'
import { PlaybackControlAffix } from './components/PlaybackControlAffix.tsx'
// import AudioContextListener from './AudioContextListener.tsx'
// import ExternalLinksView from './components/ExternalLinksView.tsx'
// import { PlaybackControlAffix } from './components/PlaybackControlAffix.tsx'

function App() {
    const {
        parsedPiece,
        setParsedPiece,
        debouncedEditorContent,
        selectedLine,
        selectedPlaybackItem,
        setSelectedPlaybackItem,
        doPlayAudio,
        setDoPlayAudio,
        setBpm,
        setMicrotimingStrength,
        setMicrotimingPattern,
        setPdfUrl,
        activePieceView,
        setActivePieceView,
        activeLeftPieceView,
        mobileOpened,
        toggleMobile,
        closeMobile,
        playbackFollowsTextCursor,
    } = useFileContext()

    const cancelTokenSource = useRef<CancelTokenSource | null>(null)

    const reloadPattern = (val: string) => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Operation canceled by the user.')
            // console.log('cancelled previous request')
        }

        cancelTokenSource.current = axios.CancelToken.source()

        axios
            .post('/api/piece/parse/', val, {
                headers: { 'Content-Type': 'text/plain' },
                cancelToken: cancelTokenSource.current.token,
            })
            .then(
                (response) => {
                    const data = response.data
                    setParsedPiece(data)
                    // console.log(data);
                },
                (error) => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message)
                    } else {
                        console.log(error)
                        Sentry.captureException(error)
                    }
                }
            )
    }

    const previousePieceBpm = usePrevious(parsedPiece?.bpm)
    const previousePieceMicrotimingStrength = usePrevious(
        parsedPiece?.microtiming_strength
    )
    const previousePieceMicrotimingPattern = usePrevious(
        parsedPiece?.microtiming_pattern
    )
    useEffect(() => {
        // @ts-expect-error for debugging
        window.parsedPiece = parsedPiece
        if (parsedPiece) {
            // update bpm from piece if it changes there
            if (parsedPiece.bpm !== previousePieceBpm || !previousePieceBpm) {
                setBpm(parsedPiece.bpm)
            }
            if (
                parsedPiece.microtiming_strength !==
                previousePieceMicrotimingStrength
            ) {
                setMicrotimingStrength(parsedPiece.microtiming_strength)
            }
            if (
                parsedPiece.microtiming_pattern !==
                previousePieceMicrotimingPattern
            ) {
                setMicrotimingPattern(parsedPiece.microtiming_pattern)
            }
        }
    }, [parsedPiece])

    useEffect(() => {
        // console.log('debouncedEditorContent changed', debouncedEditorContent)
        reloadPattern(debouncedEditorContent)
    }, [debouncedEditorContent])

    // const onChange = React.useCallback((val: string, viewUpdate) => {
    //     setEditorContent(val)
    // }, [])

    useHotkeys(
        'ctrl+shift=p, cmd+shift+p',
        (e) => {
            e.preventDefault()
            setDoPlayAudio(!doPlayAudio)
        },
        [doPlayAudio]
    )

    useEffect(() => {
        // console.log('line selected:', selectedLine, 'parsed piece', parsedPiece)
        if (!playbackFollowsTextCursor) return

        if (!parsedPiece) {
            setSelectedPlaybackItem(null)
            return
        }

        if (!(selectedLine.toString() in parsedPiece.line_map)) {
            const first = parseInt(Object.keys(parsedPiece.line_map)[0])
            const last = parseInt(Object.keys(parsedPiece.line_map)[-1])
            if (selectedLine > first && selectedLine <= last)
                setSelectedPlaybackItem(null)
        } else {
            const entry = parsedPiece.line_map[selectedLine]
            setSelectedPlaybackItem({
                name: entry.element_name || 'Standard',
                instrument_name: null,
                itemtype:
                    entry.element_type == 'voice' ? 'part' : entry.element_type,
            })
        }

        if (!selectedPlaybackItem) {
            // set first from line map
            const first = parseInt(Object.keys(parsedPiece.line_map)[0])
            const entry = parsedPiece.line_map[first]
            setSelectedPlaybackItem({
                name: parsedPiece.line_map[first].element_name || 'Standard',
                instrument_name: null,
                itemtype:
                    entry.element_type == 'voice' ? 'part' : entry.element_type,
            })
        }
    }, [selectedLine, parsedPiece, playbackFollowsTextCursor])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const updatePDFURL = async () => {
            try {
                const response = await fetch('/api/create-pdf/', {
                    method: 'POST',
                    body: debouncedEditorContent,
                    signal: signal,
                })

                const blob = await response.blob()
                const url = URL.createObjectURL(blob)

                setPdfUrl(url)

                // Cleanup function for revoking the blob URL
                return () => {
                    URL.revokeObjectURL(url)
                }
            } catch (error: unknown) {
                // @ts-expect-error error is unknown
                if (error.name !== 'AbortError') {
                    console.error('Error:', error)
                }
            }
        }

        updatePDFURL()

        return () => {
            controller.abort()
        }
    }, [debouncedEditorContent])

    const pdfViewer = (
        <div
            className="split-pane"
            id="pdf-viewer"
            // style={{
            //     height: 'calc(100vh - var(--app-shell-header-height, 100px) - var(--app-shell-footer-height, 100px))',
            // }}
        >
            <IframePDFViewer></IframePDFViewer>
        </div>
    )

    const codeEditorView = <CodeEditor />

    const visualPieceView = (
        <>
            <Space />
            {/*<Card>*/}
            <PieceGridView />
            {/*</Card>*/}

            <Space m={'xl'} />
            <Container>
                <Card radius="md">
                    <RightSidebarContents showPlaybackItemControl={false} />
                </Card>
            </Container>
            <Space mt="xl" />

            {/*<ExternalLinksView />*/}
            {/*<Space mt="xl" />*/}
        </>
    )

    const leftPane = (
        <div className="split-pane" id="left-pane">
            <HeaderMenu />

            {activeLeftPieceView == 'code' && (
                <>
                    <PlaybackControlAffix />
                    <ScrollArea
                        type={'auto'}
                        h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px) - 50px) "
                    >
                        {codeEditorView}
                    </ScrollArea>{' '}
                </>
            )}

            {activeLeftPieceView == 'preview' && (
                <>
                    <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
                        {visualPieceView}
                    </ScrollArea>
                </>
            )}
        </div>
    )

    const isMobile = useMediaQuery('(max-width: 768px)')

    const griddivhorizontal = useRef(null)

    const grid = (
        <>
            <Split
                //@ts-expect-error split plane missing typescript definition
                render={({
                    //@ts-expect-error split plane missing typescript definition
                    getGridProps,
                    //@ts-expect-error split plane missing typescript definition
                    getGutterProps,
                }) => (
                    <div
                        ref={griddivhorizontal}
                        className="grid grid-horizontal"
                        {...getGridProps()}
                    >
                        {leftPane}

                        <div
                            className="resize-gutter gutter-col gutter-col-1"
                            {...getGutterProps('column', 1)}
                        />

                        {pdfViewer}

                        <div
                            className="resize-gutter gutter-col gutter-col-2"
                            {...getGutterProps('column', 3)}
                        />

                        <div className="split-pane" id="audio-sidebar">
                            <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
                                <RightSidebarContents
                                    showPlaybackItemControl={true}
                                />
                            </ScrollArea>
                        </div>
                    </div>
                )}
            />
        </>
    )

    const viewportRef = useRef<HTMLDivElement>(null)

    const editorView = (
        <>
            {/*<PieceViewSwitcher />*/}
            <Stack gap={'0'} className={'mainStack'}>
                {grid}
            </Stack>
        </>
    )

    const appcontent = !isMobile ? (
        editorView
    ) : (
        <Tabs value={activePieceView} onChange={() => toggleMobile()}>
            {/*<Tabs.List>*/}
            {/*    <Tabs.Tab value="first">First tab</Tabs.Tab>*/}
            {/*    <Tabs.Tab value="second">Second tab</Tabs.Tab>*/}
            {/*</Tabs.List>*/}

            <Tabs.Panel value="editor">
                {/*<PlaybackControlAffix />*/}
                <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
                    {codeEditorView}
                </ScrollArea>
            </Tabs.Panel>
            <Tabs.Panel value="notes">
                <Affix
                    position={{ top: 12, right: 10 }}
                    hidden={activePieceView != 'notes'}
                >
                    <ExportPDFButton />
                </Affix>
                <Stack
                    h={
                        'calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))'
                    }
                >
                    {pdfViewer}
                </Stack>
            </Tabs.Panel>
            {/*<Tabs.Panel value="library">*/}
            {/*    <Split*/}
            {/*        //@ts-expect-error split plane missing typescript definition*/}
            {/*        render={({*/}
            {/*            //@ts-expect-error split plane missing typescript definition*/}
            {/*            getGridProps,*/}
            {/*            //@ts-expect-error split plane missing typescript definition*/}
            {/*            getGutterProps,*/}
            {/*        }) => (*/}
            {/*            <div*/}
            {/*                ref={griddivhorizontal}*/}
            {/*                className="grid grid-two"*/}
            {/*                {...getGridProps()}*/}
            {/*            >*/}
            {/*                <LibraryView />*/}
            {/*                */}
            {/*                <div*/}
            {/*                    className="resize-gutter gutter-col gutter-col-1"*/}
            {/*                    {...getGutterProps('column', 1)}*/}
            {/*                />*/}

            {/*                <div className="split-pane" id="audio-sidebar">*/}
            {/*                    <ScrollArea h={'calc(100vh - 60px)'}>*/}
            {/*                        <RightSidebarContents />*/}
            {/*                    </ScrollArea>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</Tabs.Panel>*/}
            <Tabs.Panel value="audiocontrols">
                <ScrollArea.Autosize mah="calc(100vh - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px))">
                    <Container mt={'xl'} mb="7rem">
                        <RightSidebarContents showPlaybackItemControl={true} />
                    </Container>
                </ScrollArea.Autosize>
            </Tabs.Panel>
            <Tabs.Panel value="piecegrid">
                <ScrollArea h={'calc(100vh - 60px)'}>
                    <PieceGridView />
                    <Space mt="5rem" />
                </ScrollArea>
            </Tabs.Panel>
        </Tabs>
    )

    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure()

    const appshell = (
        <AppShell
            header={{ height: 60 }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
            }}
            footer={{ height: 0 }}
            padding={0}
            // hidden
            disabled={!isMobile}
        >
            <AppShell.Header>
                <Group h="100%" px="md">
                    <Burger
                        opened={mobileOpened}
                        onClick={toggleMobile}
                        hiddenFrom="sm"
                        size="sm"
                    />
                    <Burger
                        opened={desktopOpened}
                        onClick={toggleDesktop}
                        visibleFrom="sm"
                        size="sm"
                    />
                    <Text fs="30" fw={600}>
                        GrooveDoc
                        {/*{parsedPiece?.properties.name*/}
                        {/*    ? parsedPiece?.properties.name*/}
                        {/*    : 'GrooveDoc'}*/}
                    </Text>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md">
                {/*<NavLink*/}
                {/*    label="Library"*/}
                {/*    leftSection={<IconHome2 size="1rem" stroke={1.5} />}*/}
                {/*    onClick={() => setActivePieceView('library')}*/}
                {/*/>*/}
                <NavLink
                    active={activePieceView === 'piecegrid'}
                    label="Playback"
                    leftSection={<IconLayoutGrid size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('piecegrid')
                        closeMobile()
                    }}
                />
                <NavLink
                    active={activePieceView === 'notes'}
                    label="Notation"
                    leftSection={<IconFile size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('notes')
                        closeMobile()
                    }}
                />
                <NavLink
                    active={activePieceView === 'editor'}
                    label="Editor"
                    leftSection={<IconEdit size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('editor')
                        closeMobile()
                    }}
                />

                <NavLink
                    active={activePieceView === 'audiocontrols'}
                    label="Audio Settings"
                    leftSection={<IconAdjustments size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('audiocontrols')
                        closeMobile()
                    }}
                />

                <Space mt={'md'} />
                <OpenFileButton />
                <Space mt={'md'} />
                <SaveFileButton />
                {/*<Space mt={'md'} />*/}
                <NavLink
                    label={'Privacy Policy'}
                    href={
                        'https://www.privacypolicies.com/live/47d6f0f5-3d92-4ad2-b2e5-0e78430148ed'
                    }
                    mt={'2rem'}
                />
            </AppShell.Navbar>
            <AppShell.Main>
                <Container fluid ref={viewportRef} p={0} m={0}>
                    {appcontent}
                </Container>
            </AppShell.Main>
            <AppShell.Footer p={0} m={0}></AppShell.Footer>
        </AppShell>
    )

    const myColor: MantineColorsTuple = [
        '#fff8df',
        '#fff0ca',
        '#ffdf99',
        '#ffcd63',
        '#ffbe36',
        '#ffb518',
        '#ffb003',
        '#e49a00',
        '#cb8800',
        '#b07500',
    ]

    const theme = createTheme({
        colors: {
            prim: myColor,
        },
        primaryColor: 'prim',
        // primaryColor: 'FFBC2E',
    })
    return (
        <MantineProvider defaultColorScheme="dark" theme={theme}>
            <Notifications />
            {/*<AudioContextListener />*/}
            <MultiInstrumentPlayer />
            {appshell}
        </MantineProvider>
    )
}

export default App
