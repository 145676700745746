import { Container, Group, Space } from '@mantine/core'
import classes from './HeaderSimple.module.css'
import OpenFileButton from './OpenFileButton.tsx'
import SaveFileButton from './SaveFileButton.tsx'
import ExportPDFButton from './ExportPDFButton.tsx'
// import { ShareButton } from './ShareButton.tsx'
import { useMediaQuery } from '@mantine/hooks'
import { EditPreviewSwitch } from './EditPreviewSwitch.tsx'

export function HeaderMenu() {
    const isMobile = useMediaQuery('(max-width: 768px)')
    return (
        <Group className={classes.header} gap="xs" wrap="nowrap">
            <OpenFileButton />
            <SaveFileButton />
            <Container fluid />
            {isMobile && <ExportPDFButton />}
            {!isMobile && <EditPreviewSwitch />}
            <Space w="xs" />
            {/*<ShareButton />*/}
            {/*<Burger*/}
            {/*    opened={opened}*/}
            {/*    onClick={toggle}*/}
            {/*    hiddenFrom="xs"*/}
            {/*    size="sm"*/}
            {/*/>*/}
        </Group>
    )
}
