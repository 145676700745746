import React from 'react'
import { useFileContext } from '../context/FileContext.tsx'
import {
    Table,
    Container,
    Card,
    Center,
    Group,
    Space,
    Title,
    Skeleton,
    Divider,
    rem,
    Text,
    darken,
    Button,
} from '@mantine/core'
import { GridPlayButton } from './GridPlayButton.tsx'
import ExternalLinksView from './ExternalLinksView.tsx'
import { IconVolume } from '@tabler/icons-react'
import ExportPDFButton from './ExportPDFButton.tsx'
import { useMediaQuery } from '@mantine/hooks'

const PieceGridView: React.FC = () => {
    const { parsedPiece, mutedInstruments, toggleInstrumentMute } =
        useFileContext()
    const isMobile = useMediaQuery('(max-width: 768px)')

    if (!parsedPiece) {
        return (
            <>
                <Container>
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                </Container>
            </>
        )
    }

    // Instrument Column titles
    const instruments = parsedPiece.instrument_names

    // PlaybackItem name row titles
    const rowNames = parsedPiece.part_names

    const data = rowNames.map((rowName) => (
        <Table.Tr key={rowName}>
            <Table.Td>
                <GridPlayButton
                    playbackItemDesc={{
                        name: rowName,
                        itemtype: 'part',
                        instrument_name: null,
                    }}
                >
                    {rowName}
                </GridPlayButton>
            </Table.Td>
            {instruments.map((instrument) => (
                <Table.Td key={instrument}>
                    {/*{parsedPiece.playback_items[rowName].tracks}*/}
                    {Object.prototype.hasOwnProperty.call(
                        parsedPiece.playback_voices,
                        // @ts-expect-error fix later
                        [rowName, instrument]
                    ) ? (
                        <GridPlayButton
                            playbackItemDesc={{
                                name: rowName,
                                itemtype: 'voice',
                                instrument_name: instrument,
                            }}
                        />
                    ) : (
                        <Card radius={'md'} />
                    )}
                </Table.Td>
            ))}
        </Table.Tr>
    ))

    const sequencecontrols = parsedPiece.sequence_names.map((sequenceName) => (
        <GridPlayButton
            key={sequenceName}
            playbackItemDesc={{
                name: sequenceName,
                itemtype: 'sequence',
                instrument_name: null,
            }}
        >
            {sequenceName}
        </GridPlayButton>
    ))

    // const link_label = (
    //     <Group p={0} gap={rem(5)}>
    //         <IconLink size={15} /> {/* Adjust the size as needed */}
    //         <Text lineClamp={1} size={rem(15)}>
    //             Links
    //         </Text>
    //     </Group>
    // )

    const playback_label = (
        <Group p={0} gap={rem(5)}>
            <IconVolume size={15} /> {/* Adjust the size as needed */}
            <Text lineClamp={1} size={rem(15)}>
                Listen
            </Text>
        </Group>
    )

    return (
        <>
            <Container size="xs">
                <Card mt="xl" radius="md">
                    <Space h="xs" />
                    {/*{parsedPiece.properties.note_topright ? (*/}
                    {/*    <Title*/}
                    {/*        // mt="1rem"*/}
                    {/*        size={rem(14)}*/}
                    {/*        fw={500}*/}
                    {/*        ta="right"*/}
                    {/*        c="dimmed"*/}
                    {/*    >*/}
                    {/*        {parsedPiece.properties.note_topright?.trim()}*/}
                    {/*    </Title>*/}
                    {/*) : (*/}
                    {/*    <Space h="xs" />*/}
                    {/*)}*/}
                    <Title
                        order={1}
                        size={rem(24)}
                        // fw={700}
                        lineClamp={1}
                        ta="center"
                    >
                        {parsedPiece.properties.name?.trim()}
                    </Title>

                    {parsedPiece.properties.subtitle && (
                        <Title mt="0.3rem" ta="center" size={rem(16)} fw={700}>
                            {parsedPiece.properties.subtitle?.trim()}
                        </Title>
                    )}

                    {parsedPiece.properties.note_topright && (
                        <Title mt="0.5rem" ta="center" size={rem(14)} fw={500}>
                            {parsedPiece.properties.note_topright.trim()}
                        </Title>
                    )}

                    {parsedPiece.properties.links.length ? (
                        <>
                            <Space h="md" />
                            {/*<Space h="md" />*/}
                            {/*<Divider label={link_label} labelPosition="left" />*/}
                            {/*<Divider />*/}
                            <Space h="xs" />
                            <ExternalLinksView />
                            <Space h={rem(4)} />
                        </>
                    ) : (
                        <></>
                    )}
                    {isMobile ? (
                        <>
                            {/*<Space h="md" />*/}

                            <Center m="xs" mt="lg">
                                <ExportPDFButton />
                            </Center>

                            {/*<Space h="xs" />*/}
                        </>
                    ) : (
                        parsedPiece.properties.links.length == 0 && (
                            <Space h="xs" />
                        )
                    )}
                </Card>

                {/*<Divider*/}
                {/*    m={'lg'}*/}
                {/*    // label={'Sequences'} labelPosition={'right'}*/}
                {/*/>*/}

                {/*<ExternalLinksView />*/}

                <Space
                    m={'xl'}
                    // label={'Parts and Voices'}
                    // labelPosition={'right'}
                />

                <Divider label={playback_label} />
                <Space
                    m={'xl'}
                    // label={'Parts and Voices'}
                    // labelPosition={'right'}
                />

                {parsedPiece.sequence_names.length > 0 && (
                    <>
                        {/*<Divider m="md" mb="md" />*/}
                        <Group
                            justify={'center'}
                            ml="lg"
                            mr="lg"
                            mb="xl"
                            mt="xs"
                            gap="xs"
                        >
                            {sequencecontrols}
                        </Group>
                        {/*<Space h="xs" />*/}
                    </>
                )}

                <Table
                    // stickyHeader
                    // stickyHeaderOffset={60}
                    withRowBorders={false}
                    p={0}
                    m={0}
                    verticalSpacing={5}
                    horizontalSpacing={5}
                >
                    {/*<Card radius={'xl'} m={'xl'} p={'xs'}>*/}

                    {/*</Card>*/}
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th />
                            {instruments.map((instrumentName) => (
                                <Table.Th key={instrumentName}>
                                    <Button
                                        key={instrumentName}
                                        variant={
                                            mutedInstruments.has(instrumentName)
                                                ? 'light'
                                                : 'filled'
                                        }
                                        color={
                                            mutedInstruments.has(instrumentName)
                                                ? '#455262'
                                                : darken('#455262', 0.4)
                                        }
                                        // color={
                                        //     mutedInstruments.has(instrumentName)
                                        //         ? 'gray'
                                        //         : // @ts-expect-error works
                                        //           instrumentColors[instrumentName]
                                        // }
                                        p={0}
                                        size={'sm'}
                                        onClick={() =>
                                            toggleInstrumentMute(instrumentName)
                                        }
                                        fullWidth={true}
                                        radius="md"
                                        fw={460}
                                    >
                                        {instrumentName
                                            .toLowerCase()
                                            .startsWith('dj')
                                            ? instrumentName.slice(0, 2)
                                            : instrumentName
                                                  .slice(0, 1)
                                                  .toUpperCase()}
                                    </Button>
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{data}</Table.Tbody>
                </Table>

                <Space h={'lg'} />
            </Container>
        </>
    )
}

export default PieceGridView
