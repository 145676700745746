import * as Tone from 'tone'
import { PitchLevels } from './types/ParsedPiece'

interface Spec {
    [id: string]: {
        start: number
        duration: number
    }
}
class Instrument {
    name: string
    spec: Spec
    players: { [id: string]: Tone.Player }
    samplers: { [id: string]: Tone.Sampler }
    loaded: boolean
    channel: Tone.Channel
    buffer: Tone.ToneAudioBuffer | null
    loadPromise: Promise<void>
    sampleMap: { [id: string]: string[] }
    pitch_levels: string[] | null
    is_pitched: boolean

    constructor(
        name: string,
        spec: Spec,
        audioUrl: string,
        stereo_pan: number,
        volume: number,
        sampleMap: { [id: string]: string[] },
        is_pitched: boolean,
        pitch_levels: PitchLevels
    ) {
        this.name = name
        this.spec = spec
        this.players = {}
        this.samplers = {}
        this.loaded = false
        this.channel = new Tone.Channel(volume, stereo_pan)
        this.channel.toDestination()
        this.buffer = null
        this.sampleMap = sampleMap
        this.is_pitched = is_pitched
        this.pitch_levels = pitch_levels

        this.loadPromise = new Promise((resolve, reject) => {
            this.buffer = new Tone.Buffer(
                audioUrl,
                () => {
                    // -------------------------------------------------
                    // Once the buffer is loaded, decide what to build:
                    // -------------------------------------------------
                    if (!this.is_pitched) {
                        // -----------------------------------------
                        // 1) Create Tone.Players (unpitched)
                        // -----------------------------------------
                        for (const sample_name in this.spec) {
                            const sample_desc = this.spec[sample_name]
                            const startTime = sample_desc.start / 1000
                            const duration = sample_desc.duration / 1000

                            const player = new Tone.Player({
                                url: this.buffer!,
                                loop: false,
                                autostart: false,
                            }).connect(this.channel)

                            // @ts-expect-error Tone.js private props
                            player.sampleStart = startTime
                            // @ts-expect-error Tone.js private props
                            player.sampleDuration = duration

                            this.players[sample_name] = player
                        }
                    } else {
                        // -----------------------------------------
                        // 2) Create Tone.Samplers (pitched)
                        // -----------------------------------------
                        const originalBuffer = this.buffer?.get() as AudioBuffer
                        const sampleRate = originalBuffer.sampleRate

                        // For each key in sampleMap, we create ONE Sampler
                        for (const [mapKey, sampleNames] of Object.entries(
                            this.sampleMap
                        )) {
                            // We'll build the Sampler's `urls` object:
                            // { [midiNoteString]: AudioBuffer | Tone.ToneAudioBuffer }
                            const samplerUrls: Record<
                                string,
                                AudioBuffer | Tone.ToneAudioBuffer
                            > = {}

                            sampleNames.forEach((sampleName, idx) => {
                                const slice = this.spec[sampleName]
                                if (!slice) {
                                    console.warn(
                                        `Slice "${sampleName}" not found in spec.`
                                    )
                                    return
                                }
                                // Calculate sample frames
                                const startSample = Math.floor(
                                    (slice.start / 1000) * sampleRate
                                )
                                const sliceLength = Math.floor(
                                    (slice.duration / 1000) * sampleRate
                                )
                                const endSample = startSample + sliceLength

                                // Create a new sub AudioBuffer
                                const subAudioBuffer = new AudioBuffer({
                                    length: sliceLength,
                                    numberOfChannels:
                                        originalBuffer.numberOfChannels,
                                    sampleRate: originalBuffer.sampleRate,
                                })

                                // Copy the data from the big buffer
                                for (
                                    let channel = 0;
                                    channel < originalBuffer.numberOfChannels;
                                    channel++
                                ) {
                                    const originalData =
                                        originalBuffer.getChannelData(channel)
                                    const subData =
                                        subAudioBuffer.getChannelData(channel)
                                    subData.set(
                                        originalData.subarray(
                                            startSample,
                                            endSample
                                        ),
                                        0
                                    )
                                }

                                // Wrap it in a ToneAudioBuffer (optional but handy):
                                const toneSubBuffer = new Tone.ToneAudioBuffer(
                                    subAudioBuffer
                                )

                                // Determine which pitch level to assign:
                                const midiNote = this.pitch_levels![idx]
                                if (midiNote === undefined) {
                                    console.warn(
                                        `No pitch level found for sample "${sampleName}" at index ${idx}. Skipping.`
                                    )
                                    return
                                }

                                samplerUrls[midiNote.toString()] = toneSubBuffer
                            })

                            // Create one Sampler for this mapKey
                            const sampler = new Tone.Sampler({
                                urls: samplerUrls,
                                // onload: () => {
                                //     console.log(
                                //         `Sampler for "${mapKey}" loaded.`
                                //     )
                                // },
                            })
                            sampler.volume.value = volume
                            // }).connect(this.channel)
                            // sampler.toDestination()

                            // const stereoWidener = new Tone.StereoWidener(0.95)
                            // sampler.connect(stereoWidener).toDestination()
                            // stereoWidener.connect(this.channel)
                            sampler.toDestination()

                            // const reverb = new Tone.Reverb({
                            //     decay: 1.5,
                            //     wet: 0.2,
                            // }).toDestination()
                            //
                            // sampler.connect(reverb)

                            this.samplers[mapKey] = sampler
                        }
                    }

                    this.loaded = true
                    resolve()
                },
                (error) => {
                    console.error(
                        `Error loading buffer for ${this.name}:`,
                        error
                    )
                    reject(error)
                }
            )
        })
    }

    getVolume() {
        if (!this.is_pitched) {
            return this.channel.volume.value
        } else {
            return this.samplers[Object.keys(this.samplers)[0]].volume.value
        }
    }

    setVolume(volume: number) {
        if (!this.is_pitched) {
            this.channel.volume.value = volume
        } else {
            for (const sampler in this.samplers) {
                this.samplers[sampler].volume.value = volume
            }
        }
    }

    mute() {
        this.channel.mute = true
    }

    unmute() {
        this.channel.mute = false
    }

    isMuted() {
        return this.channel.mute
    }

    async play(player_id: string, time: number, verbose = false) {
        if (!this.loaded) {
            await this.loadPromise
        }
        // console.log(id)
        const player = this.players[player_id]
        if (player) {
            // @ts-expect-error tone js
            player.start(time, player.sampleStart, player.sampleDuration)
            if (verbose) {
                console.log(
                    `${this.name} playing sample ID: ${player_id} at time: ${Tone.getTransport().position}`
                )
            }
        } else {
            console.error(
                `Player ID "${player_id}" of "${this.name}" not found.`
            )
            console.log(this.players)
        }
    }

    randomChoice(arr: string[]) {
        if (!Array.isArray(arr) || arr.length === 0) {
            throw new Error('The argument must be a non-empty array.')
        }
        return arr[Math.floor(Math.random() * arr.length)]
    }

    async create_part(
        starts: number[],
        sounds: string[],
        notes: string[],
        length: number
    ): Promise<Tone.Part> {
        if (!this.loaded) {
            await this.loadPromise
        }

        const playback_spec = starts.map((start, index) => [
            `0:0:${start}`,
            [sounds[index].toString(), notes[index]],
        ])
        const loopEnd = `0:0:${length}`

        const part = new Tone.Part((time, value) => {
            // console.log(value)
            // const note = Number.parseInt(value[1])
            const note = value[1]
            const sound = value[0]

            let sample = null
            if (this.pitch_levels) {
                // console.log('note', note)
                // console.log('this.pitch_levels', this.pitch_levels)

                // const index = this.pitch_levels.indexOf(note)
                // console.assert(
                //     index !== -1,
                //     `Note ${note} not found in pitch levels`
                // )
                // sample = samples[index]
                // console.log(note, sample)
                // console.log(this)
                this.samplers[sound].triggerAttack(
                    note,
                    // Tone.Frequency(note),
                    time
                )
            } else {
                const samples = this.sampleMap[sound]
                sample = this.randomChoice(samples)
                // console.log('random')
                this.play(sample, time)
            }
        }, playback_spec)

        part.loop = true
        part.loopEnd = loopEnd
        return part
    }
}

export { Instrument }
